<template>
    <v-dialog v-if="isVisible" v-model="isVisible" transition="dialog-top-transition" scrollable persistent max-width="1100">

        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ getTitle }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="isLoading">

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text v-else class="wrapperFormModal">
                
                <!-- Секция выбора источников -->
                <v-card 
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Поиск") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="text-field-with-icon">
                                    <v-text-field
                                        v-model="search"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-search"
                                        clearable
                                        @click:clear="search = ''"
                                    ></v-text-field>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>

                <!-- Секция Выбрать / Выбрано -->
                <v-row class="modal-select-list-two-row-wrap" dense>

                    <v-col cols="12" sm="12" md="6">
                        <v-card 
                            flat
                        >
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Доступно") }}</div>
                                <v-list class="modal-select-list" dense outlined>
                                    <v-virtual-scroll
                                        :items="allowed"
                                        height="251"
                                        item-height="50"
                                    >
                                        <template v-slot:default="{ item }">
                                            <v-list-item
                                                :key="allowed.indexOf(item)"
                                                :value="item"
                                                @click="addSelected(item)"
                                                v-tooltip.top-center="{ content: item[1].length > 56 ? item[1] : '' }"
                                            >
                                                <v-list-item-content>                                                            
                                                    <v-list-item-title v-text="item[1]"></v-list-item-title>
                                                    <v-list-item-subtitle>{{ item[3] }}</v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>

                                    </v-virtual-scroll>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                        <v-card 
                            flat
                        >
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Выбрано") }}</div>
                                <v-list 
                                    class="modal-select-list 
                                    modal-select-list-two" 
                                    dense 
                                    outlined
                                    height="251"
                                >
                                    <v-list-item-group
                                        color="cyan darken-1"
                                    >
                                        <v-list-item
                                            v-for="(item, i) in getSelected"
                                            :key="i"
                                            :value="item"
                                            @click="removeSelected(item)"
                                        >
                                            <template v-if="item.banned === true">
                                                
                                                <v-list-item-content v-tooltip.top-center="'Запись недоступна для выбора'">
                                                    <v-list-item-title class="text--disabled">
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="text--disabled">
                                                        {{ item.description }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-avatar
                                                    :right="true"
                                                >
                                                    <v-icon
                                                        small
                                                        color="black darken-2"
                                                    >
                                                        fas fa-ban
                                                    </v-icon>
                                                </v-list-item-avatar>
                                            </template>
                                                
                                            <template v-else>
                                                <v-list-item-content v-tooltip.top-center="{ content: item.name.length > 56 ? item.name : '' }">
                                                    <v-list-item-title>
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ item.description }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>

                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn 
                color="cyan" 
                text
                depressed 
                @click="ok" 
                v-if="!isOkDisabled">
                    {{$t("Выбрать")}}
                </v-btn>

                <v-btn 
                color="blue-grey" 
                text
                depressed
                @click="cancel">
                    {{$t("Отмена")}}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "SelectMembersDlg",
    data () {
        return {
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            showtree: false
        }
    },
    computed: {
        ...mapGetters('dialogs/selectMembers', ['isVisible', 'isVisibleTree', 'allowed', 'isOkDisabled', 'getTitle', 'isLoading', 'getSelected']),
        search: {
            get: function() {
                return this.$store.getters['dialogs/selectMembers/getSearch'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectMembers/SET_SEARCH', v);
            }
        },
        /*source: {
            get: function() {
                return this.$store.getters['dialogs/selectMembers/getSource'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectMembers/SET_SOURCE', v);
            }
        },*/
    },
    methods: {
        ...mapActions('dialogs/selectMembers', ['ok', 'cancel', 'removeSelected', 'addSelected']),
    }
}
</script>